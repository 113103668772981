/* blog editor */

@font-face {
    font-family: "SVN-Helvetica";
    /* src: url("./font/SVN-Helvetica\ Neue\ Regular.ttf") format("truetype"); */

    font-weight: normal;
    font-size: medium;
}

.mce-toc {
    background: #f1f1f1;
    border-radius: 12px;
    padding: 10px;
}
.mce-toc h2 {
    color: #000;
}
.mce-toc ul,
.mce-toc li {
    list-style-type: none;
    padding-bottom: 5px;
}
.mce-toc li a {
    color: #4287f6;
}
.mce-toc img {
    border-radius: 10px !important;
}

b,
strong {
    font-weight: 600;
}
